footer {
	background-color: #161616;
	width: 100%;
	min-height: 204px;
	padding: 20px;
	color: white;
}

/* Wide footer (desktops, tablets, or landscape) */
footer,
.wide {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

footer .links-row {
	align-items: center;
	color: #858585;
	display: flex;
	justify-content: space-evenly;
	font-size: 15px;
	font-weight: 500;
}

footer .links-col {
	align-items: center;
	color: #858585;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	font-size: 15px;
	font-weight: 500;
}

/* Hyperlinks (unset before styling to clear default styling) */
footer a {
	all: unset;
	cursor: pointer;
	transition: 150ms ease-out;
	margin: 5px;
}

footer a:hover {
	color: white;
	font-weight: bold;
	transition: 150ms ease-out;
}
