input,
textarea {
	all: unset;
	background: linear-gradient(
		90.74deg,
		#404040 0%,
		rgba(69, 69, 69, 0.35) 100%
	);
	border-radius: 10px;
	font-size: 16px;
	font-weight: 500;
	padding: 14px 23px;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: white;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	color: white;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: white;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	color: white;
}

input::placeholder,
textarea::placeholder {
	color: white;
}

.button2 {
	background-color: #454545;
	padding: 16px;
}
