nav {
	align-items: center;
	background-color: #161616;
	box-shadow: 0px 10px 20px #161616a3;
	color: white;
	display: flex;
	align-content: space-between;
	font-family: "Bebas Neue";
	font-size: 25px;
	height: 60px;
	max-height: 60px;
	padding-left: 23px;
	padding-right: 23px;
}

nav .right {
	align-items: center;
	display: flex;
	position: absolute;
	right: 23px;
}

.sticky {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
}

.navbar-text {
	font-size: 26px;
	font-family: "Bebas Neue";
	color: white;
}

.navbar-icon {
	height: 20px;
	color: white;
}
