.button3 {
	color: #858585;
	font-weight: 500;
	transition: 150ms ease-out;
}

.button3:hover {
	font-weight: 600;
	transition: 150ms ease-out;
}
