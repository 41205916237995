.hidden {
	opacity: 0;
}

/* Card */
.card {
	background: linear-gradient(135deg, #262626 0%, rgba(72, 72, 72, 0) 100%);
	border-radius: 25px;
	color: white;
	display: flex;
	flex-direction: row;
	font-size: 14.5px;
	font-weight: 600;
	padding: 35px;
	transition: all 300ms ease-out;
}

.card-hover:hover {
	background: rgb(219, 219, 219);
}

/* Texts */
.card-hover:hover .white {
	color: black;
}

/* Images */
.card-hover:hover .svg {
	-webkit-filter: invert(100%);
	filter: invert(100%);
}

/* Embedded card */
.card .card-2 {
	background: linear-gradient(135deg, #383838 0%, rgba(72, 72, 72, 0) 100%);
}

.card-hover:hover .card-2 {
	background: transparent;
}

/* Animations */
.fade-in {
	opacity: 0;
	-webkit-animation: fade-in-anim 1s ease-out forwards;
	animation: fade-in-anim 1s ease-out forwards;
}

@-webkit-keyframes fade-in-anim {
	0% {
		opacity: 0;
		transform: scale(80%) translateY(50px);
	}
	100% {
		opacity: 1;
		transform: scale(100%) translateY(0px);
	}
}

@keyframes fade-in-anim {
	0% {
		opacity: 0;
		transform: scale(80%) translateY(50px);
	}
	100% {
		opacity: 1;
		transform: scale(100%) translateY(0px);
	}
}
