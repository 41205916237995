.marquee-content {
	color: white;
	display: inline-block;
	font-family: "Marck Script", sans-serif;
	font-size: 20px;
	font-weight: 200;
	margin: "0px 10px";
}

.dot {
	display: inline-block;
	width: 8px;
	height: 8px;
	background-color: rgb(88, 164, 246);
	margin: 0 5px;
	border-radius: 50%;
}
