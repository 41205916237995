.title {
	font-size: 16px;
	font-weight: 600;
}

.content {
	color: #c2c2c2;
	font-size: 16px;
	font-weight: 500;
}

.content + .title {
	margin-top: 35px;
}
